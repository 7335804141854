import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import elegancyTitle from '../../assets/images/home/elegancy-title.png'

import elegancy1 from '../../assets/images/home/elegancy-1.png'
import elegancy2 from '../../assets/images/home/elegancy-2.png'
import elegancy3 from '../../assets/images/home/elegancy-3.png'
import elegancy4 from '../../assets/images/home/elegancy-4.png'


function Elegancy() {
  return (
    <div>
      <div className='elegancy'>
        <div className='container'>
          <div className='row'>
            <div className='elg-col col-12 col-md-6 col-lg-3' data-aos="fade-up">
              <img className='img img-fluid mb-4' src={elegancy1} />
            </div>
            <div className='elg-col col-12 col-md-6 col-lg-3' data-aos="fade-up" data-aos-delay="100">
              <div className='elg-title'></div>
              <img className='img img-fluid' src={elegancy2} />
            </div>
            <div className='elg-col col-12 col-md-6 col-lg-3' data-aos="fade-up" data-aos-delay="200">
              <p className='elg-text'>
                Kami memegang teguh tradisi dengan tetap mempertahankan elegansi dan kemewahan di setiap karya kami.
              </p>
              <img className='img img-fluid elg-img-3' src={elegancy3} />
              <p className='elg-text'>
                Seluruh karya kami menjujung tinggi kualitas dan keakuratan terdapat tradisi yang ingin kami bawakan. Make up artist kami merupakan ahli dalam penyajian adat tradisional dan telah tersertifikasi secara nasional.
              </p>
            </div>
            <div className='col-12 col-md-6 col-lg-3' data-aos="fade-up" data-aos-delay="400">
              <img className='img img-fluid' src={elegancy4} />
            </div>
          </div>
        </div>
      </div>
      <div className='elegancy-mobile'>
        <div className='container'>
          <div className='row'>
          <div className='elg-col col-12 col-md-6 col-lg-3'>
              <div className='elg-title'></div>
              <img className='img img-fluid mb-4' src={elegancy2} />
            </div>
            <div className='elg-col col-12 col-md-6 col-lg-3'>
              <img className='img img-fluid mb-4' src={elegancy1} />
            </div>
            <div className='elg-col col-12 col-md-6 col-lg-3'>
              <p className='elg-text'>
                Kami memegang teguh tradisi dengan tetap mempertahankan elegansi dan kemewahan di setiap karya kami.
              </p>
              <img className='img img-fluid elg-img-3' src={elegancy3} />
              <p className='elg-text'>
                Seluruh karya kami menjujung tinggi kualitas dan keakuratan terdapat tradisi yang ingin kami bawakan. Make up artist kami merupakan ahli dalam penyajian adat tradisional dan telah tersertifikasi secara nasional.
              </p>
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <img className='img img-fluid' src={elegancy4} />
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Elegancy;