import React, { useState } from 'react';

function RiasTeamProfile({ riasTeamName, riasTeamDesc, riasTeamImg }) {
  return (
    <div className='col-12 col-md-6 col-lg-3 card mot-card justify-content-center'>
      <img className='mot-img img-fluid' src={riasTeamImg} alt={riasTeamName} />
      <div className='mot-img-mobile img-fluid' style={{backgroundImage: `url(${riasTeamImg})`}} />
      <div className='mot-text'>
        <p className='mot-name'>{ riasTeamName }</p>
        <hr className='hr-mobile' />
        <p className='mot-mua'>Make Up Artist</p>
      </div>
    </div>
  );
}

export default RiasTeamProfile;