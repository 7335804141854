import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import RiasTeamProfile from './RiasTeamProfile';

import trIntro from '../../assets/images/tata-rias/tr-intro-img.png';

function TataRiasIntro() {
  return (
    <div className='tata-rias-intro'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-4 col-lg-4'>
            <img className='img img-fluid' data-aos="fade-right" src={trIntro} />
          </div>
          <div className='col-12 col-md-4 col-lg-8'>
            <p className='tr-intro-text' data-aos="fade-up" data-aos-delay="100">
            Makeup artist House of Liza merupakan seniman-seniman terbaik dalam industri dengan dedikasinya pada detail. Setiap sapuan makeupnya dikerjakan dengan teliti, menggarisbawahi keindahan alami setiap individu. Selain itu, keahlian merka dalam mewujudkan look impian pengantin di hari spesial tidak dapat disangkal. Dengan sentuhan magis, makeup artist kami  berhasil menciptakan tampilan yang mempesona, memberikan kepercayaan diri kepada setiap pengantin yang dipercayakan pada kami. </p>
            
            <p className='tr-intro-text' data-aos="fade-up" data-aos-delay="150">Hal ini mencerminkan bagaimana kami memegang teguh tradisi kecantikan serta kebudayaan yang berakar dalam setiap langkahnya, menjadikan setiap pengalaman bersama House of Liza tidak hanya sekadar sesi makeup, tetapi juga sebuah perjalanan budaya yang mendalam dan berkesan.</p>

            <div className='tr-intro-text-img' data-aos="fade-up"></div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default TataRiasIntro;