import react, { useState, useEffect, useRef } from 'react';

import miniMapPlaceholder from '../../assets/images/kontak/mini-map.png'

function MiniMap() {
  return (
    <div className='order-2 order-md-2 order-lg-1 col-12 col-md-12 col-lg-6'>
      <img className='img img-fluid' src={miniMapPlaceholder} />
    </div>
  );
}

export default MiniMap;
