import { Link } from 'react-router-dom';

import rightArrow from '../../assets/icons/right-arrow.svg'

function BlogPreviewComponent({blogImg, blogTitle, blogBy, blogDate, blogText, blogLink}) {
  function truncateText(text, wordLimit = 25) {
    // Split the text into an array of words
    const words = text.split(' ');
  
    // Slice the array to get the first 'wordLimit' words
    const truncatedWords = words.slice(0, wordLimit);
  
    // Join the truncated words back into a string
    const truncatedText = truncatedWords.join(' ');
  
    // Add "..." at the end
    return truncatedText + '...';
  }

  function formatLongDate(longDate) {
    const date = new Date(longDate);
    
    const options = {
      month: 'short', // Short month name (e.g., Jan)
      day: 'numeric',  // Day of the month (e.g., 23)
      year: 'numeric', // Full year (e.g., 2024)
    };
  
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  
  return (
    <div className='blog-preview justify-content-center'>
      <div className='bp-inner'>
        <div className='row'>
          <img className='bp-img img img-fluid col-12 col-md-12 col-lg-4' src={blogImg} />
          <div className='bp-body col-12 col-md-12 col-lg-8'>
            <div className='row'>
              <p className='bp-desc col-12'>By {blogBy} | {formatLongDate(blogDate.toString())}</p>
              <Link className='bp-link col-12' to={blogLink}><h2 className='bp-title'>{blogTitle}</h2></Link>
              <p className='bp-preview col-12'>{truncateText(blogText)}</p>
            </div>
          </div>
        </div>
        <hr className='bp-hr' />
      </div>
    </div>
  );
}

export default BlogPreviewComponent;
