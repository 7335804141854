import { useEffect } from 'react';
import NavigationBarLight from '../../NavigationBarLight';
import Footer from '../../Footer';


function BlogEntry2() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='blog-entry be-2'>
      <NavigationBarLight />
      <div className='container'>
        <div className='be-content'>
          <img className='be-2-img be-img img-responsive' src="https://img.kabarindo.com/uploads/images/image_big_4110066c04cadb6358.jpg"/>
          <h2 className='be-2-title be-title'>Fashion Show House Of Liza “Indonesian Heritage Diversity”: Tampilkan Koleksi Tradisional Kekinian dan Modern</h2>
          <p className='be-2-desc be-desc'>by Kabarindo (Orie Buchori) | Sabtu, 17 Agustus 2024</p>
          <p className='be-text'>KABARINDO, JAKARTA - Dalam rangka memperingati Hari Kemerdekaan Indonesia, House Of Liza, salah satu nama besar dalam industri Wedding Indonesia, menggelar pagelaran busana bertajuk “Indonesian Heritage Diversity" berkolaborasi bersama Venue The Tribrata, Jumat (16/8/2024) di Opus Grand ballroom ,The Tribrata Hotel & Conventions, Darmawangsa - Jakarta.
          <br/>
          <br/>
          “Indonesian Heritage Diversity” adalah sebuah perayaan keindahan dan kekayaan warisan budaya Indonesia yang diwujudkan melalui karya-karya busana yang memadukan elemen tradisional dan modern," papar Fitri Liza kepada awal media usai pagelaran fashion shownya.</p>
          <br/><br/>

          <img className="be-img img-responsive" src="https://old.kabarindo.com/uploads/files/file_66c056f98156d.jpg"/>
          <br/><br/>
          <p className='be-text'>
          Koleksi yang ditampilkan kali ini, lanjut Fitri Liza, merupakan representasi House Of Liza  menampilkan busana tradisional kekinian yang terinspirasi oleh keragaman budaya Nusantara, yang diolah menjadi busana kontemporer yang tetap memancarkan nuansa keaslian tradisi.
          <br/><br/>
          "Riasan tradisional namun tetap kekinian dan modern agar tetap mengikuti kemauan pasar, karena anak-anak saat ini sudah masuk gen Z nih, jadi mereka senang tradisional tapi harus dimodifikasi agar tidak terlalu vintage. Jadi fashion show ini tema besarnya adalah ada keanekaragaman rias pengantin Indonesia dalam modifikasi yang tetap mengikuti jalur pakemnya," jelas Liza.
          <br/><br/>
          <img className='be-img img-responsive' src="https://old.kabarindo.com/uploads/files/file_66c0573bc3e3c.jpg"/>
          <br/><br/>
          Warna-warna yang digunakan dalam koleksi ini diambil dari palet yang sedang menjadi tren di industri fashion global, namun dengan sentuhan unik berupa detail ukiran dan motif tradisional yang mencerminkan identitas budaya Indonesia. 
          <br/><br/>
          "Motif batik, tenun ikat, songket, dan berbagai jenis kain tradisional lainnya dipadu dengan potongan modern, menciptakan tampilan yang elegan dan penuh makna," terang Liza lagi.
          <br/><br/>
          <img className='be-img img-responsive' src="https://old.kabarindo.com/uploads/files/file_66c057ab54fc2.jpg"/>
          <br/><br/>
          <h3 className='be-subheader'>​Riasan Tradisional dengan Sentuhan Modern</h3>

          Selain busana, aspek riasan dan tata rambut juga menjadi fokus utama dalam acara ini. House Of Liza menampilkan teknik-teknik riasan tradisional dari berbagai daerah di Indonesia, namun dengan sentuhan modern yang membuatnya lebih relevan dengan tren saat ini. 
          <br/><br/>
          "Untuk busana pengantin yang kami tampilkan yaitu dari Jawa Tengah, Solo Putri, Madura atau Jawa Timur, kemudian untuk Pulau Sumateranya ada Melayu lalu Indonesia Timurnya ada Bali. Hanya ada 1 unsur modern yang kami tampilkan yaitu Pengantin MuslimHijab Modern," pungkas Liza.
          <br/><br/>
          <img className='be-img img-responsive' src="https://old.kabarindo.com/uploads/files/file_66c05c6641e24.jpg"/>
          <br/><br/>
          Sekedar informasi, House of Liza berdiri sejak tahun 1978 dan sudah tiga kali berganti nama. Yang pertama Liza Salon, Sanggar Liza dan terakhir mengenakan nama House of Liza yang berada di Jalan  Asem Baris Raya, Kebon Baru, Tebet, Jakarta Selatan. Foto: Orie Buchori dan Dok. House of Liza
          </p>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogEntry2;
