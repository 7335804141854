import React, { useState } from 'react';

import ManagementProfile from './ManagementProfile'

import ManagementHeadshot from '../../assets/images/tentang/mgmt-headshot.png';

function Management() {
  return (
    <div className='management'>
      <div className='mg-inner container'>
        <h2 className='mg-title' data-aos="fade-up"><span className='white'>Meet</span> the Management</h2>
        <div className='row mg-content justify-content-center'>
          <div className='mg-img' data-aos="fade-up"></div>
          <div className='mg-profile-list row justify-content-center'>
            <div className='mg-profile col-6 col-md-6 col-lg-2'>
              <h2 className='mg-name' data-aos="fade-up" data-aos-delay="300">Fitri Liza</h2>
              <p className='mg-pos' data-aos="fade-up" data-aos-delay="300">Marketing manager</p>
            </div>
            <div className='mg-profile col-6 col-md-6 col-lg-2'>
              <h2 className='mg-name' data-aos="fade-up" data-aos-delay="200">Raga</h2>
              <p className='mg-pos' data-aos="fade-up" data-aos-delay="200">Operational manager</p>
            </div>
            <div className='mg-profile col-6 col-md-6 col-lg-2'>
              <h2 className='mg-name' data-aos="fade-up" data-aos-delay="100">Ibu Liza</h2>
              <p className='mg-pos' data-aos="fade-up" data-aos-delay="100">Founder</p>
            </div>
            <div className='mg-profile col-6 col-md-6 col-lg-2'>
              <h2 className='mg-name' data-aos="fade-up" data-aos-delay="200">Lety Liza</h2>
              <p className='mg-pos' data-aos="fade-up" data-aos-delay="200">Finance Manager</p>
            </div>
            <div className='mg-profile col-6 col-md-6 col-lg-2'>
              <h2 className='mg-name' data-aos="fade-up" data-aos-delay="300">Reny Liza</h2>
              <p className='mg-pos' data-aos="fade-up" data-aos-delay="300">Production Manager</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Management;