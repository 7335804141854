import react, { useState, useEffect, useRef } from 'react';

import BlogPreviewComponent from './BlogPreviewComponent';

let blogs = require('../../assets/json/blog-entries-preview.json')

function BlogContent() {

  return (
    <div className='blog-content'>
      <div className='container'>
        <div className='container'>
          <div className='bc-main-container row'>
          {blogs.map((blog, index) => (
            <BlogPreviewComponent 
              key={index} // Use a unique key, typically blog ID or index
              blogImg={blog.img} 
              blogBy={blog.author} 
              blogDate={blog.date} 
              blogTitle={blog.title} 
              blogText={blog.preview} 
              blogLink={blog.link}
            />
          ))}
            {/* <BlogPreviewComponent blogImg={blogImg} blogBy={blogBy.current} blogDate={blogDate.current} blogTitle={blogTitle1.current} blogText={blogText1.current} blogLink={blogLink1.current}/> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogContent;
