import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import bannerImg from '../../assets/images/home/banner-img.png'

function MainBanner() {
  return (
    <div className='main-banner'>
      <div className='container'>
        <div className='main-banner-inner row'>
          <div className='order-2 order-md-2 order-lg-1 col-12 col-md-6 col-lg-6 mb-desc'>
            <div className='mb-text' data-aos="fade-up">
              <div className='mb-header'>
              </div>
              <h2 className='mb-smalltext'>Jadikan hari bahagiamu bersama House of Liza</h2>
            </div>
            <div className='text-center'>
              <Link to="https://wa.me/628118019985?text=Halo%20Mican,%20aku%20mau%20konsultasi%20tentang%20layanan di House of Liza." target="__blank"><button className='mb-btn'>Konsultasi Gratis Sekarang</button></Link>
              <Link to="https://www.instagram.com/house_of_liza/" target="__blank"><button className='mb-btn'>Kunjungi Instagram</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainBanner;