import { Link } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';

import NavigationBar from '../components/NavigationBar'
import Footer from '../components/Footer'

import FeaturedComponent from '../components/tata-busana/FeaturedComponent';
import Loader from '../components/Loader';
import BeskapFeatured from '../components/tata-busana/BeskapFeatured';
import KebayaFeatured from '../components/tata-busana/KebayaFeatured';
import KainFeatured from '../components/tata-busana/KainFeatured';
import SongketFeatured from '../components/tata-busana/SongketFeatured';
import AksesorisFeatured from '../components/tata-busana/AksesorisFeatured';
import DodotFeatured from '../components/tata-busana/DodotFeatured';
import variables from '../variables';

function TataBusana() {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${variables.api}/v1/products`); // Replace with your API endpoint
        const res = await response.json();
        
        setItems(res.data.products);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    setItems([])
    fetchData();

  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='tata-busana'>
      <NavigationBar />
      <div className='tb-banner'>
        <div className='container'>
          <div className='col-12 col-md-6 col-lg-6'>
            <div className='tb-header' data-aos="fade-up"></div>
          </div>

        </div>
      </div>
      <div className='tb-featured-container'>
        <KebayaFeatured />
        <BeskapFeatured />
        <KainFeatured />
        <SongketFeatured />
        <DodotFeatured />
        <AksesorisFeatured />
      </div>
      <Footer />
    </div>
  );
}

export default TataBusana;
