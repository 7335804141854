import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../Loader';
import variables from '../../variables';

function TataRiasGallery() {
  const [img, setImg] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await fetch(`${variables.api}/v1/gallery/makeup`); // Replace with your API endpoint
        const res = await response.json();
        setImg(res.data.make_up);
      
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    setImg([])
    fetchData();
  }, [])

  function generateImages() {
    if (loading) {
      return <Loader />
    } else {
      var allImg = []

      img.map((adat) => (
        adat.make_up_detail && adat.make_up_detail.map((photo) => (
          allImg.push(<div 
            key={photo.id} 
            className='col-6 col-md-6 col-lg-3 trg-img-container'
            data-aos="fade-up"
            data-aos-delay={`${(allImg.length ? allImg.length-1 : allImg.length)%4}00`}>
              <div className='img img-fluid trg-img-inner'>
              </div>
            {photo.image_name ? (
              <img className='img img-fluid trg-img' src={photo.image_name} alt={photo.title} />
            ) : (
              <Loader /> 
            )}
          </div>)
        ))
      ))
      allImg.sort((a,b) => b.key - a.key); 
      console.log(allImg)
      return allImg.splice(0,8)
      
    }
  }

  return (
    <div className='tata-rias-gallery'>
      <div className='container'>
        <div className='trg-title' data-aos="fade-right"></div>
        <div className='trg-gallery-container row g-2'>
          {generateImages()}
        </div>

        <div>
          
        </div>
        <div className='trg-btn-outer'>
          <Link className='trg-btn-outer' to="/galeri/make-up">
            <p className='trg-btn'>
              Lihat Semua
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TataRiasGallery;