import { useEffect } from 'react';
import NavigationBarLight from '../../NavigationBarLight';
import Footer from '../../Footer';


function BlogEntry8() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='blog-entry be-8'>
      <NavigationBarLight />
      <div className='container'>
        <div className='be-content'>
          <img className='img-responsive be-6-img be-img' src="https://fokal.id/wp-content/uploads/2024/08/House-Of-Liza-Fashion-Show-4-scaled-e1724035262133-1140x570.jpg"/>
          <h2 className='be-6-title be-title'>Melestarikan Warisan Budaya Lewat Koleksi Busana</h2>
          <p className='be-6-desc be-desc'>by Erat Indonesia | August 19, 2024</p>
          <p className='be-text'>House of Liza, Pionir Industri pernikahan Indonesia, menggelar pagelaran busana yang bukan hanya menampilkan koleksi baju mereka, tetapi juga menceritakan kisah tentang kekayaan budaya Indonesia.
          <br/>
          <br/>
          Desainer House of Liza berhasil membuat setiap koleksi tetap relevan dan memesona. Berbagai elemen tradisional, seperti batik, songket, dipadukan dengan gaya modern yang segar sehingga menghasilkan penampilan yang apik.
          <br/>
          <br/>
          Palet warna yang dipilih bukan hanya mengikuti tren global, tetapi dipadukan dengan detail ukuran dan motif yang memiliki akara sejarah nusantara. Hasilnya? Lini koleksi yang tidak hanya mengundang decak kagum, tetapi juga menggugah rasa bangga akan warisan budaya yang tidak ternilai. </p>
          <br/>
          <br/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogEntry8;
