import { Link } from 'react-router-dom';
import { Timeline } from 'gsap/gsap-core';
import { useEffect } from 'react';

function Loader() {
  //animate first circle
  var tl = new Timeline({repeat: -1});

  //animate second circle
  var tl2 = new Timeline({repeat: -1});

  useEffect(() => {
    tl.set("#c1", {autoAlpha:.7})
    .to("#c1", .5, {scale: .2, x: "+=5", transformOrigin:"50% 50%"})
    .to("#c1", .5, {scale:1, x: "-=5",transformOrigin:"50% 50%"} );
  
    tl2.set("#c2", {autoAlpha:.7})
    .to("#c2", .5, {scale: .2, x: "-=5",transformOrigin:"50% 50%"})
    .to("#c2", .5, {scale:1, x: "+=5",transformOrigin:"50% 50%"} )  
  }, [])
    
  return (
    <svg viewBox="0 0 16 16" width="40px" height="40px"  version="1.1"
        xmlns="http://www.w3.org/2000/svg">
      <circle id="c1" cx="8" cy="8" r="8" fill="#1a1a1a" />
      <circle id="c2" cx="14" cy="8" r="8" fill="#ba8f1a" />
    </svg>
  );
}

export default Loader;
