import react, { useState, useEffect } from 'react';

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import BlogContent from '../components/blog/BlogContent';

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='blog'>
      <NavigationBar />
      <div className='blog-banner'>
      </div>
      <BlogContent />
      <Footer />
    </div>
  );
}

export default Blog;
