import react, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import CardComponent from '../CardComponent';

import fotografiService1 from '../../assets/images/fotografi/fg-layanan-1.png'
import fotografiService2 from '../../assets/images/fotografi/fg-layanan-2.png'
import fotografiService3 from '../../assets/images/fotografi/fg-layanan-3.png'
import fotografiService4 from '../../assets/images/fotografi/fg-layanan-4.png'

function FotografiServices() {
  return (
    <div className='fs-container'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-12 col-lg-4' data-aos="fade-up">
            <h2 className='fs-header'>LAYANAN KAMI</h2>
          </div>
          <div className='col-12 col-md-12 col-lg-8'>
            <div className='row'>
              <div className='fs-elmt col-6 col-md-3 col-ld-3' data-aos="fade-up">
                <img src={fotografiService1} className='fs-img img img-fluid' />
                <p className='fs-name'>Wedding</p>
                <Link className='fs-book' to="https://wa.me/628118019985?text=Halo%20Admin,%20aku%20mau%20booking%20layanan%20Wedding%20Photo%20di%20Liza%20Photography." target="__blank">
                  Book Now
                </Link>
              </div>
              <div className='fs-elmt col-6 col-md-3 col-ld-3' data-aos="fade-down" data-aos-delay="100">
                <p className='fs-name'>Pre Wedding</p>
                <Link className='fs-book' to="https://wa.me/628118019985?text=Halo%20Admin,%20aku%20mau%20booking%20layanan%20Pre%20Weddingo%20di%20Liza%20Photography." target="__blank">
                  Book Now
                </Link>
                <img src={fotografiService2} className='fs-img img img-fluid'/>
              </div>
              <div className='fs-elmt fs-elmt-border-mobile col-6 col-md-3 col-ld-3' data-aos="fade-up" data-aos-delay="200">
                <img src={fotografiService3} className='img img-fluid' />
                <p className='fs-name'>Photo Studio</p>
                <Link className='fs-book' to="https://wa.me/628118019985?text=Halo%20Admin,%20aku%20mau%20booking%20layanan%20Photo%20Studio%20di%20Liza%20Photography." target="__blank">
                  Book Now
                </Link>
              </div>
              <div className='fs-elmt-border-mobile col-6 col-md-3 col-ld-3' data-aos="fade-down" data-aos-delay="300">
                <p className='fs-name'>Beauty Shoot</p>
                <Link className='fs-book' to="https://wa.me/628118019985?text=Halo%20Admin,%20aku%20mau%20booking%20layanan%20Beauty%20Shoot%20di%20Liza%20Photography." target="__blank">
                  Book Now
                </Link>
                <img src={fotografiService4} className='fs-img img img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FotografiServices;
