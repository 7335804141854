import react, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import CardComponent from '../CardComponent';

import fotografiGallery1 from '../../assets/images/fotografi/fg-gl-1.png'
import fotografiGallery2 from '../../assets/images/fotografi/fg-gl-2.png'
import fotografiGallery3 from '../../assets/images/fotografi/fg-gl-3.png'
import fotografiGallery4 from '../../assets/images/fotografi/fg-gl-4.png'

function FotografiServices() {
  return (
    <div className='fg-gl-container'>
      <div className='container'>
        <div className='row'>
          <div className='fg-gl-img-container fg-gl-img-border col-6 col-md-6 col-lg-3' data-aos="fade-up">
            <img className='fg-gl-img img img-fluid' src={fotografiGallery1} />
          </div>
          <div className='fg-gl-img-container fg-gl-img-border col-6 col-md-6 col-lg-3' data-aos="fade-down">
            <img className='fg-gl-img img img-fluid' src={fotografiGallery2} />
          </div>
          <div className='fg-gl-img-container fg-gl-img-border col-6 col-md-6 col-lg-3' data-aos="fade-up" data-aos-delay="100">
            <img className='fg-gl-img img img-fluid' src={fotografiGallery3} />
          </div>
          <div className='fg-gl-img-container col-6 col-md-6 col-lg-3' data-aos="fade-down" data-aos-delay="100">
            <img className='fg-gl-img img img-fluid' src={fotografiGallery4} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FotografiServices;
