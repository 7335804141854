import { useEffect } from 'react';
import NavigationBarLight from '../../NavigationBarLight';
import Footer from '../../Footer';


function BlogEntry2() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='blog-entry be-2'>
      <NavigationBarLight />
      <div className='container'>
        <div className='be-content'>
          <img className='be-2-img be-img img-responsive' src="https://img.kabarindo.com/uploads/images/image_big_4110066c04cadb6358.jpg"/>
          <h2 className='be-2-title be-title'>FDi Hari Kemerdekaan House Of Liza Menghadirkan Fashion Show</h2>
          <p className='be-2-desc be-desc'>by Freemagz | Sabtu, 17 Agustus 2024</p>
          <p className='be-text'>Freemagz.id - Dalam rangka memperingati Hari Kemerdekaan Indonesia, House Of Liza, salah satu nama besar dalam industri Wedding Indonesia, menyelenggarakan sebuah pagelaran busana yang bertajuk “Indonesian Heritage Diversity. Acara ini merupakan Kolaborasi Bersama antara Venue The Tribrata dan House of Liza , berlangsung pada 16 Agustus 2024 di Opus Grand ballroom ,The Tribrata Hotel & Conventions, Darmawangsa - Jakarta.
          <br/>
          <br/>
          <img className='be-img img-responsive' src="https://freemagz.id/wp-content/uploads/2024/08/IMG_6300.jpgs"/>          
          “Indonesian Heritage Diversity” adalah sebuah perayaan keindahan dan kekayaan warisan budaya Indonesia yang diwujudkan melalui karya-karya busana yang memadukan elemen tradisional dan modern," papar Fitri Liza kepada awal media usai pagelaran fashion shownya.</p>
          <br/><br/>
          <img className='be-img img-responsive' src="https://freemagz.id/wp-content/uploads/2024/08/Untitled-1-8.jpg"/>
          <br/><br/>
          <p className='be-text'>
          Warna-warna yang digunakan dalam koleksi ini diambil dari palet yang sedang menjadi tren di industri fashion global, namun dengan sentuhan unik berupa detail ukiran dan motif tradisional yang mencerminkan identitas budaya Indonesia. Motif batik, tenun ikat, songket, dan berbagai jenis kain tradisional lainnya akan dipadu dengan potongan modern, menciptakan tampilan yang elegan dan penuh makna.
          <br/><br/>
          <img className='be-img img-responsive' src="https://old.kabarindo.com/uploads/files/file_66c0573bc3e3c.jpg"/>
          <br/><br/>
          <h3 className='be-subheader'>​Riasan Tradisional dengan Sentuhan Modern</h3>
          <br></br>
          Selain busana, aspek riasan dan tata rambut juga menjadi fokus utama dalam acara ini. House Of Liza menampilkan teknik-teknik riasan tradisional dari berbagai daerah di Indonesia, namun dengan sentuhan modern yang membuatnya lebih relevan dengan tren saat ini. 
          </p>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogEntry2;
