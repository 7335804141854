import { useEffect } from 'react';
import NavigationBarLight from '../../NavigationBarLight';
import Footer from '../../Footer';


function BlogEntry5() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='blog-entry be-3'>
      <NavigationBarLight />
      <div className='container'>
        <div className='be-content'>
          <img className='img-responsive be-3-img be-img' src="https://img.herstory.co.id/articles/archive_20240818/house-of-liza-20240818-145004.webp"/>
          <h2 className='be-3-title be-title'>Peringati Kemerdekaan, House of Liza Helat Fashion Show 'Indonesian Heritage Diversity' yang Modern tapi Tetap Tradisional!</h2>
          <p className='be-3-desc be-desc'>by Herstory, Azka Elfriza | August 18, 2024</p>
          <p className='be-text'>HerStory, Jakarta — Gelaran industri pernikahan Indonesia rupanya ikut mewarnai keseruan Hari Kemerdekaan Indonesia. Dalam rangkaian acara untuk memperingati Ulang Tahun Indonesia, House Of Liza menyelenggarakan sebuah pagelaran busana yang bertajuk “Indonesian Heritage Diversity" lho Beauty!
          <br/>
          <br/>
          Acara ini merupakan Kolaborasi Bersama antara Venue The Tribrata dan House of Liza , berlangsung pada 16 Agustus 2024 di Opus Grand ballroom ,The Tribrata Hotel & Conventions, Darmawangsa - Jakarta.
          <br/>
          <br/>
          “Indonesian Heritage Diversity” adalah sebuah perayaan keindahan dan kekayaan warisan budaya Indonesia yang diwujudkan melalui karya-karya busana yang memadukan elemen tradisional dan modern. Koleksi yang akan diperkenalkan dalam acara ini merupakan buah karya desainer House Of Liza yang terinspirasi oleh keragaman budaya Nusantara, yang diolah menjadi busana kontemporer yang tetap memancarkan nuansa keaslian tradisi.</p>
          <br/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogEntry5;
