import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import footerLogo from '../assets/images/logo/full-logo.png';

function Footer() {
  return (
      <div className='footer'>
        <div className='footer-inner container'>
          <div className='ft-elmts row'>
            <div className='col-12 col-md-12 col-lg-6 ft-log text-center'>
              <img className='ft-logo-img' src={footerLogo} alt="Logo" />
            </div>
            <div  className='col-12 col-md-12 col-lg-6'>
              <div className='row'>
                <hr className='ft-hr-mobile' />
              <div className='col-12 col-md-12 col-lg-6 ft-office'>
                <h2 className='ft-title'>KANTOR</h2>
                <p className='ft-text'>GRHA Liza</p>
                <p className='ft-text'>Jl. Asem Baris Raya No.11A, RT.4/RW.3 Kebon Baru, Tebet, South Jakarta City, Jakarta 12830</p>
              </div>
              <hr className='ft-hr-mobile' />
              <div className='col-12 col-md-12 col-lg-6 ft-contact'>
                <h2 className='ft-title'>HUBUNGI KAMI</h2>
                <p className='ft-text'>+62 21 22008879</p>
                <p className='ft-text'>+62 811 8019 985</p>
                <p className='ft-text'>sanggarliza@gmail.com</p>
              </div>
              </div>
              <p className='ft-copyright mt-5'>2024 Hak Cipta Terpelihara oleh PT Liza Makmur Mandiri</p>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Footer;