import { useEffect, useState } from 'react';

import variables from '../variables';

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import Loader from '../components/Loader';

function GaleriMakeUp() {
  const [img, setImg] = useState([])
  const [loading, setLoading] = useState(true)

  // function formatAndCapitalize(input) {
  //   // Replace hyphens with spaces and capitalize each word
  //   return input
  //     .replace(/-/g, ' ')
  //     .replace(/\b\w/g, (char) => char.toUpperCase());
  // }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await fetch(`${variables.api}/v1/gallery/makeup`); // Replace with your API endpoint
        const res = await response.json();
        setImg(res.data.make_up);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [])
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='galeri galeri-make-up'>
      <NavigationBar />
      <div className='gmu-banner'>
        <div className='container'>
          <div className='gmu-header col-12 col-md-6 col-lg-6' data-aos="fade-up"></div>
        </div>
      </div>
      <div className='galeri-body gmu-body'>
        <div className='container'>
          <div className='row g-2'>
            {loading ? <Loader /> :
            img.map((adat) => (
              adat.make_up_detail && adat.make_up_detail.map((photo) => (
                <div key={photo.id} className='col-6 col-md-4 col-lg-3 galeri-img-container' data-aos="fade-up">
                  {photo.image_name ? (
                    <img className='galeri-img img img-fluid svg-img' src={photo.image_name} alt={photo.title} />
                  ) : (
                    <Loader /> 
                  )}
                </div>
              ))
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GaleriMakeUp;
