import React, { useState } from 'react';

import vm from '../../assets/images/tentang/visi-img.png';

function VisiMisi() {
  return (
    <div className='visi-misi'>
      <div className='vm-inner container'>
        <div className='row'>
          <div className='vm-img-container col-12 col-md-12 col-lg-4'>
            <img className='vm-img img-fluid' data-aos="fade-right" src={vm} alt="Visi dan Misi" />
          </div>
          <div className='col-12 col-md-12 col-lg-8'>
            <div className='vm-title' data-aos="fade-right" data-aos-delay="100"></div>
            <div className='vm-visi'>
              <h2 className='vm-visi-title' data-aos="fade-up" >OUR VISION</h2>
              <p className='vm-visi-text' data-aos="fade-up">Menjadi penyedia jasa layanan lengkap pernikahan tradisional maupun modern yang menjadi kebanggaan Indonesia dan melayani seluruh pengantin di penjuru Indonesia.</p>
            </div>
            <div className='vm-misi'>
              <h2 className='vm-misi-title' data-aos="fade-up">OUR MISSION</h2>
              <p className='vm-misi-text' data-aos="fade-up">Menjadikan The Liza Wedding sebagai layanan pernikahan yang fokus pada 3P (People, Product & Partnership)</p>
              <hr className='vm-hr' data-aos="fade-up"/>
              <p className='vm-misi-text' data-aos="fade-up">Berkomitmen untuk memberikan layanan dengan penuh integritas, amanah & service yang excellence kepada para calon pengantin</p>
              <hr className='vm-hr' data-aos="fade-up"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisiMisi;