import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import leftArrow from '../assets/icons/left-arrow.svg'
import rightArrow from '../assets/icons/right-arrow.svg'

const Pagination = ({ current, totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(current);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
  
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
  
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={`pg-number pg-elmt ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
  
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <span key="ellipsis" className="ellipsis">
            ...
          </span>
        );
      }
      pageNumbers.push(
        <span
          key={totalPages}
          className={`pg-number pg-elmt ${totalPages === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </span>
      );
    }
  
    return pageNumbers;
  };
  

  return (
    <div className="pagination justify-content-center">
      <span
        className={`pg-arrow pg-elmt ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={() => currentPage !== 1 && handlePageChange(currentPage - 1)}
      >
        <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
      </span> 
      {renderPageNumbers()}
      <span
          className={`pg-arrow pg-elmt ${currentPage === totalPages ? 'disabled' : ''}`} 
          onClick={() => currentPage !== totalPages && handlePageChange(currentPage + 1)}
        >
          <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
        </span>
    </div>
  );
};

export default Pagination;
