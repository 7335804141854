import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import kerjasamaIcon from '../../assets/icons/kerjasama.png'
import medalIcon from '../../assets/icons/medal.png'
import thumbIcon from '../../assets/icons/thumb.png'
import smileyIcon from '../../assets/icons/smiley.png'

function KenapaMemilih() {
  return (
    <div className='kenapa-memilih'>
      <div className='km-content container'>
        <div className='row'>
          <div className='col-12 col-md-12 col-lg-6 km-title-container d-flex justify-content-center'>
            <div className='km-title' data-aos="fade-up">
            </div>
          </div>

          <div className='col-12 col-md-12 col-lg-6 km-content-elmt'>
            <div className='row'>
             <div className='km-elmt-desc col-12 col-md-6 col-lg-6' data-aos="fade-up" data-aos-delay="100">
                <img className='km-elmt-icon' src={kerjasamaIcon} />
                <h2 className='km-elmt-title'>KERJASAMA TIM</h2>
                <p className='km-elmt-text'>Memastikan bahwa kebahagiaan dan kepuasan pelanggan adalah prioritas utama dalam setiap aspek pekerjaan</p>
              </div>
              <div className='km-elmt-desc col-12 col-md-6 col-lg-6' data-aos="fade-up" data-aos-delay="200">
                <img className='km-elmt-icon' src={medalIcon} />
                <h2 className='km-elmt-title'>KUALITAS</h2>
                <p className='km-elmt-text'>Menyediakan layanan yang tidak hanya memenuhi, tetapi juga melebihi harapan klien dalam hal kualitas dan kepuasan</p>
              </div>
            </div>
            <div className='row'>
              <div className='km-elmt-desc col-12 col-md-6 col-lg-6' data-aos="fade-up" data-aos-delay="300">
                <img className='km-elmt-icon' src={thumbIcon} />
                <h2 className='km-elmt-title'>PROFESIONAL</h2>
                <p className='km-elmt-text'>Menyediakan layanan yang profesional, bertindak dengan jujur, mempertahankan etika, dan integritas dalam semua hubungan bisnis</p>
              </div>
              <div className='km-elmt-desc col-12 col-md-6 col-lg-6' data-aos="fade-up" data-aos-delay="400">
                <img className='km-elmt-icon' src={smileyIcon} />
                <h2 className='km-elmt-title'>KEPUASAN PELANGGAN</h2>
                <p className='km-elmt-text'>Memastikan bahwa kebahagiaan dan kepuasan pelanggan adalah prioritas utama dalam setiap aspek pekerjaan</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default KenapaMemilih;