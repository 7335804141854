import React, { useEffect } from 'react';

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import TataRiasBanner from '../components/tata-rias/TataRiasBanner';
import TataRiasGallery from '../components/tata-rias/TataRiasGallery';
import Carousel from '../components/Carousel';
import TataRiasIntro from '../components/tata-rias/TataRiasIntro';


function TentangKami() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='tata-rias'>
       <NavigationBar />
       <TataRiasBanner />
       <TataRiasIntro />
       <TataRiasGallery />
       <Footer />
    </div>
  );
}

export default TentangKami;
