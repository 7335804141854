import React, {useEffect } from 'react';

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';


import FotografiBanner from '../components/fotografi/FotografiBanner'
import FotografiServices from '../components/fotografi/FotografiServices';
import FotografiMiniGallery from '../components/fotografi/FotografiMiniGallery';

function Fotografi() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='fotografi'>
      <NavigationBar />
      <FotografiBanner />
      <FotografiServices />
      <FotografiMiniGallery />
      <Footer />
    </div>
  );
}

export default Fotografi;
