import react, { useState, useEffect, useRef } from 'react';

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import MiniMap from '../components/kontak/MiniMap'
import KontakForm from '../components/kontak/KontakForm';


function Kontak() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='kontak'>
      <NavigationBar />
      <div className='kontak-banner'>
        <div className='kb-text container'>
          <h2 className='kb-header'>Hubungi Kami</h2>
          <p className='kb-desc'>Tetap terhubung bersama kami</p>
        </div>
      </div>
      <div className='kontak-content container'>
        <div className='kc-inner row'>
          <MiniMap />
          <KontakForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Kontak;
