import React, { useState } from 'react';

import buLiza from '../../assets/images/tentang/profil-bu-liza.png';

function About() {
  return (
    <div className='about'>
      <div className='container'>
        <div className='row'>
          <div className='ab-desc order-2 order-md-2 order-lg-1 col-12 col-md-12 col-lg-7'>
            <div className='ab-title' data-aos="fade-right">Profil</div>
            <p className='ab-text' data-aos="fade-up" data-aos-delay="100">Pada awalnya dikenal sebagai Sanggar Liza yang merupakan sanggar tata rias dan tata busana tradisional. Sanggar Liza didirikan oleh Ibu Liza pada tahun 1978 yang awalnya merupakan salon kecantikan wajah dan rambut. Seiring berjalannya waktu Sanggar Liza terus mengembangkan bisnisnya dalam bidang tata rias dan pada akhir tahun 2023, Sanggar Liza melakukan rebranding menjadi House of Liza. </p>
            
            <p className='ab-text' data-aos="fade-up" data-aos-delay="200">Kini, Sanggar Liza telah berkembang menjadi Sanggar Tata Rias, Sanggar Tata Busana, Photography, Upacara Adat dan lain-lain, sesuai visi kami menjadi perusahaan One Stop Wedding Service. Inovasi, Kreativitas, serta Profesionalisme merupakan salah satu kiat kami dalam menarik pelanggan.</p>
          </div> 
          <div className='ab-img-container order-1 order-md-1 order-lg-2 col-12 col-md-12 col-lg-5'>
            <img className='ab-img img-fluid' src={buLiza} alt="Ibu Liza" />
          </div>  
        </div>
        </div>
    </div>
  );
}

export default About;