import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import bannerImg from '../../assets/images/tata-rias/tata-rias-banner.png'

function TataRiasBanner() {
  return (
    <div className='tr-banner'>
      <div className='container'>
        <div className='tr-banner-inner row'>
          <div className='col-12 col-md-12 col-lg-6 trb-title' data-aos="fade-up">
            <h2 className='trb-header'>TATA RIAS</h2>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default TataRiasBanner;