import './assets/css/styles.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight, faChevronDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'


import Home from './pages/Home'
import TentangKami from './pages/TentangKami';
import TataRias from './pages/TataRias';
import Fotografi from './pages/Fotografi';
import Blog from './pages/Blog';
import TataBusana from './pages/TataBusana';
import Kontak from './pages/Kontak';

import BlogEntry1 from './components/blog/entries/Blog1'
import BlogEntry2 from './components/blog/entries/Blog2'
import BlogEntry3 from './components/blog/entries/Blog3'
import BlogEntry4 from './components/blog/entries/Blog4'
import BlogEntry5 from './components/blog/entries/Blog5'
import BlogEntry6 from './components/blog/entries/Blog6'
import BlogEntry7 from './components/blog/entries/Blog7'
import BlogEntry8 from './components/blog/entries/Blog8'

import Pricelist from './pages/Pricelist';
import KatalogPageTemplate from './pages/KatalogPageTemplate';
import ItemDetailTemplate from './pages/ItemDetailTemplate';
import GaleriMakeUp from './pages/GaleriMakeUp';
import GaleriFotografi from './pages/GaleriFotografi';

library.add(faChevronLeft, faChevronRight, faChevronDown, faChevronCircleUp)

function App() {

  return (
    <BrowserRouter>
      <div className="App">
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/tentang-kami" element={<TentangKami />} />
        <Route path="/tata-rias" element={<TataRias />} />
        <Route path="/fotografi" element={<Fotografi />} />

        <Route path="/galeri/makeup" element={<GaleriMakeUp />} />
        <Route path="/galeri/photography" element={<GaleriFotografi />} />

        <Route path="/pricelist" element={<Pricelist />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/1" element={<BlogEntry1 />} />
        <Route path="/blog/2" element={<BlogEntry2 />} />
        <Route path="/blog/3" element={<BlogEntry3 />} />
        <Route path="/blog/4" element={<BlogEntry4 />} />
        <Route path="/blog/5" element={<BlogEntry5 />} />
        <Route path="/blog/6" element={<BlogEntry6 />} />
        <Route path="/blog/7" element={<BlogEntry7 />} />
        <Route path="/blog/8" element={<BlogEntry8 />} />
        {/* <Route path="/blog/:blogId" element={<BlogPostTemplate />} /> */}

        <Route path="/tata-busana" element={<TataBusana />} />
        <Route path="/tata-busana/:type" element={<KatalogPageTemplate />} />
        <Route path="/tata-busana/:type/detail/:code" element={<ItemDetailTemplate />} />

        <Route path="/kontak" element={<Kontak />} />
      </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
