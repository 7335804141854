import { useEffect } from 'react';
import NavigationBarLight from '../../NavigationBarLight';
import Footer from '../../Footer';


function BlogEntry4() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='blog-entry be-4'>
      <NavigationBarLight />
      <div className='container'>
        <div className='be-content'>
          <img className='be-4-img img-responsive be-img' src="https://img.kabarindo.com/uploads/images/image_big_4110066c04cadb6358.jpg"/>
          <h2 className='be-4-title be-title'>Rancangan Baju Pengantin House of Liza, Gaya Tradisional dan Modern!</h2>
          <p className='be-4-desc be-desc'>by IDN Times, Hani Safanja | Sabtu, 19 Agustus 2024</p>
          <p className='be-text'>DN Times, Jakarta - Dalam Wedding Open House 2024, House of Liza tampil sebagai salah satu desainer terkemuka yang berkolaborasi dalam acara tersebut. Sebagai butik yang menawarkan berbagai pilihan baju pengantin tradisional, House of Liza juga mempersembahkan karya terbaru yang terinspirasi dari kekayaan budaya Indonesia.
          <br/>
          <br/>
          Bertajuk “Indonesian Heritage Diversity,” kolaborasi House of Liza dengan The Tribata di Opus Grand Ballroom, tidak hanya menampilkan desain elegan, tetapi juga merayakan Hari Kemerdekaan dalam industri pernikahan Indonesia.Dengan menampilkan desain-desain elegan, House of Liza pun mengombinasikan konsep tradisional dengan tren modern yang terus relevan. Yuk, simak detailnya lebih lanjut di bawah ini!
          </p>
          <br/>
          <br/>
          <h3 className='be-subheader'>1. Hadirkan ragam modifikasi tradisional dengan sentuhan modern dari segi busana dan makeup</h3>
          <img className='be-img img-responsive' src="https://cdn.idntimes.com/content-images/post/20240818/house-of-liza-fashion-show-5f782a7da91a0b553149cdfc23b93bdf.JPG"/>     
          <p className='be-text'>
          <br/>
          <br/>    
          Warna-warna yang digunakan dalam koleksi ini diambil dari palet yang menjadi tren di industri fashion global. Lebih dari itu, House of Liza juga menambahkan sentuhan unik berupa detail ukiran dan motif tradisional yang mencerminkan identitas budaya Indonesia. 
          <br/>
          <br/>
          “Kami menghadirkan modifikasi berupa busana yang memiliki bordiran dan juga makeup-makeup modern yang digandrungi oleh anak-anak muda,” tutur Fitri Liza selaku Makeup Artist House of Liza di Wedding Open House 2024 (16/08/2024). 
          <br/>
          <br/>
          Sebagai one stop wedding solution, makeup dari House of Liza pun mengambil inspirasi dari berbagai media sosial yang digandrungi anak-anak muda. Menurutnya, ragam makeup kekinian menjadi kesenangan anak muda yang ditampilkan melalui warna-warna halus dan natural yang tidak terlalu bold.</p>
          <br/><br/>
          <h3 className='be-subheader'>2. Memiliki ciri khas unik berupa ukiran-ukiran pada baju pengantin</h3>
          <br/>
          <br/>
          <p className='be-text'>
          Untuk menonjolkan ciri khasnya, House of Liza menambahkan sentuhan unik melalui detail ukiran dan motif tradisional yang mencerminkan kekayaan budaya Indonesia. Mereka memadukan motif batik, tenun ikat, songket, dan berbagai kain tradisional lainnya dengan potongan modern, menciptakan tampilan yang elegan dan penuh makna.
          <br/>
          <br/>
          “Dalam hal warna, kami mengikuti tren terkini dan tidak terikat pada satu palet warna khusus. Namun, ciri khas kami terletak pada penggunaan bordir dan sulam gim,” tambah Fitri Liza. Menurutnya, setiap desainer memiliki karakternya tersendiri dan begitu pula dengan House of Liza. 
          <br/>
          <br/>
          Koleksi yang ditampilkan kali ini pun merupakan representasi House of Liza terhadap busana-busana tradisional kekinian yang memancarkan keaslian tradisi.
          </p>
          <br/>
          <br/>
          <h3 className='be-subheader'>3. Gak hanya berhiaskan payet, tetapi juga menggunakan teknik sulam gim yang rumit</h3>
          <br/>
          <br/>
          <img className='be-img img-responsive' src="https://cdn.idntimes.com/content-images/post/20240818/house-of-liza-fashion-show-3-1-18cdeac51f06c2d24c7f3c503e1a0968.jpg"/>
          <br/>
          <br/>
          <p className='be-text'>
          Selain berhiaskan payet di busana-busana tradisionalnya, House of Liza juga menggunakan sulam gim di busana pengantin, terutama kebaya Jawa. Berbeda dengan payet, sulam gim memiliki cara pengerjaan yang lebih rumit, namun memberikan hasil yang lebih ciamik. 
          <br/>
          <br/>
          “Sulam gim berbeda dengan payet dari cara pengerjaannya. Selain itu, pengrajin sulam gim juga sangat terbatas, itulah kenapa pengerjaannya lama dan hasil jadinya juga mahal,” pungkas Liza. 
          <br/>
          <br/>
          Untuk mendapatkan hasil baju pengantin tradisional yang mewah, House of Liza juga menggunakan bahan-bahan premium seperti bahan beludru, yang merupakan salah satu fabric favorit dari Fitri Liza. Buat kamu yang memiliki atau menyukai bahan tersendiri untuk baju pengantin, House of Liza juga membuka layanan untuk membawa bahan baju pengantin secara mandiri.
          </p>
          <br/>
          <br/>
          <h3 className='be-subheader'>4.  House of Liza juga menyediakan berbagai busana pengantin dari berbagai daerah Indonesia</h3>
          <br/>
          <br/>
          <img className='be-img img-responsive' src="https://cdn.idntimes.com/content-images/post/20240818/img-5897-d693f2d294c65022a838a6b7b9d47d6a.jpg" />
          <br/>
          <br/>
          <p className='be-text'>
          Selain koleksi yang dipamerkan dalam Wedding Open House, House of Liza juga memiliki showroom busana tradisional yang lengkap. Namun, untuk fashion show kali ini, House of Liza hanya membawakan beberapa baju pengantin tradisional daerah dari koleksi terbarunya.
          <br/>
          <br/>
          "Untuk fashion show kali ini, kami menampilkan busana dari beberapa daerah, termasuk Solo Putri dari Jawa Tengah, Madura dari Jawa Timur, serta Melayu dari Pulau Sumatera. Kami juga menampilkan busana dari Bali untuk Indonesia Timur. Satu elemen modern yang kami hadirkan adalah muslim hijab modern dengan nuansa ball gown," ungkap Fitri Liza. 
          <br/>
          <br/>
          Meskipun menyediakan variasi kebaya modern di koleksi terbaru, House of Liza juga menonjolkan riasan dan hair do dengan ciri khas tradisional. Mereka pun berhasil menampilkan berbagai gaya makeup tradisional dari berbagai daerah di Indonesia, lengkap dengan aksesori sebagai kebutuhan adat. Tidak hanya itu, House of Liza juga turut menawarkan berbagai jasa lainnya, termasuk penyediaan busana adat untuk hari besar nasional.
          </p>
          <br/>
          <br/>
          <h3 className='be-subheader'>5. Untuk pengerjaan busana, setidaknya memakan waktu 3 bulan pengerjaan</h3>
          <img className='be-img img-responsive' src="https://cdn.idntimes.com/content-images/post/20240818/img-5690-c7d801eccb79f811e3d6039d8c69de37.jpg"/>
          <br/>
          <br/>
          <p className='be-text'>
          Fitri Liza menjelaskan, bahwa persiapan baju pengantin membutuhkan waktu yang cukup lama. Untuk pengerjaan detail seperti payet dan bordir dibutuhkan waktu minimal 3 bulan karena setiap busana dikerjakan secara manual dengan tangan. Sementara itu, pengerjaan sulam gim pada busana tradisional memerlukan waktu lebih lama, yaitu sekitar 4 bulan.Sebagai informasi tambahan, House of Liza yang sebelumnya dikenal dengan nama Liza Salon dan Sanggar Liza, telah mengalami tiga kali pergantian nama. Pergantian nama ini merupakan bagian dari proses rebranding untuk lebih mencerminkan minat dan kebutuhan calon pengantin dari berbagai generasi.Untuk informasi lebih lanjut, reservasi, atau kesempatan kerja sama media, kamu bisa menghubungi House of Liza di nomor 08119660988 atau melalui email fitri.nursanty@gmail.com. Tertarik dengan koleksi terbaru dari House of Liza di atas?
          </p>
          <br/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogEntry4;
