import { Link, useParams } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';

import NavigationBar from '../components/NavigationBar'
import Footer from '../components/Footer'

import ItemCardComponent from '../components/ItemCardComponent';
import KatalogFilter from '../components/KatalogFilter';

import searchIcon from '../assets/icons/search.svg'
import Pagination from '../components/Pagination';
import Loader from '../components/Loader';


function KatalogPageTemplate() {
  let { type } = useParams();

  const [mounted, setMounted] = useState(false)

  const [catalogData, setCatalogData] = useState([])
  const [paginationInfo, setPaginationInfo] = useState('')
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);

  const katalogTop = useRef(null);

  const itemsPerPage = 10; // Replace with your desired items per page
  const totalItems = 100; // Replace with the total number of items

  const [query, setQuery] = useState('')

  function handleSubmit() {
    console.log("Submit")
  }

  function handleInputChange(e) {
    setQuery(e.target.value);
  }

  const handlePageChange = (p) => {
    setCurrentPage(p);
    if (katalogTop.current) {
      katalogTop.current.scrollIntoView({
        behavior: 'smooth', // Optional smooth scrolling
      });
    }
  };


  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await fetch(`https://backend-jlnko3qvaq-as.a.run.app/v1/products/${type}?page=${currentPage}&limit=18`); // Replace with your API endpoint
        const res = await response.json();
        setCatalogData(res.data.products.category_products);
        setPaginationInfo(res.data.pagination_info);
        setLoading(false);
        setMounted(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage])
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={`katalog katalog-${type}`}>
      <NavigationBar />
      <div className='kt-banner'>
        <div className='container tb-banner-inner'>
        </div>
      </div>
      <div className='kt-inner container'>
        <div className='container'>
          <form ref={katalogTop} className='kt-search row' onSubmit={handleSubmit}>
            <input
              className='kt-search-bar col-10 col-md-11 col-lg-11'
              type="text"
              placeholder="Ketik kode atau nama busana..."
              value={query}
              onChange={handleInputChange}
            />
            <button className='kt-search-btn col-2 col-md-1 col-lg-1' type="submit">
              <div className='kt-search-icon img img-fluid' style={{ maskImage: `url(${searchIcon})`, WebkitMaskImage: `url(${searchIcon})` }}></div>
            </button>
          </form>
        </div>
        <div className='row'>
          {/* <div className='col-12 col-md-12 col-lg-3'>
            <KatalogFilter />
          </div> */}
          <div className='container col-12 col-md-12 col-lg-12'>
            <div className='row justify-content-md-center justify-content-center justify-content-lg-end'>
              { loading ? <Loader /> :
                catalogData.map((item) => {
                  console.log(item)
                  return (
                    <ItemCardComponent itemCode={item.product_code} itemID={item.product_id} itemName={item.product_name} itemLink={`/tata-busana/${type}/detail/${item.product_id}`} itemImg={item.product_image_files} />
                  )
                })
              }
              { !mounted ? '' : 
                <Pagination current={paginationInfo.page} totalItems={paginationInfo.total_data} itemsPerPage={paginationInfo.data_per_page} onPageChange={handlePageChange} />
              }
            </div>

          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default KatalogPageTemplate;
