import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import navLogo from '../assets/images/logo/full-logo.png';

function NavigationBarLight() {
  const [color, setColor] = useState(false)
  const changeColor = () => {
    if (window.scrollY >= 10) {
      setColor(true)
    } else {
      setColor(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeColor)
  }, [])
  

  const [isDropdownServiceOpen, setIsDropdownServiceOpen] = useState(false);
  const [isDropdownGalleryOpen, setIsDropdownGalleryOpen] = useState(false);

  const [isServiceMobileOpen, setIsServiceMobileOpen] = useState(false)
  const [isGalleryMobileOpen, setIsGalleryMobileOpen] = useState(false)

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const handleServiceClickMobile = () => {
    if (isMobileNavOpen) {setIsServiceMobileOpen(!isServiceMobileOpen)}
  }

  const handleGalleryClickMobile = () => {
    if (isMobileNavOpen) {setIsGalleryMobileOpen(!isGalleryMobileOpen)}
  }

  const handleMouseServiceEnter = () => {
    if (!isMobileNavOpen) {setIsDropdownServiceOpen(true)};
  };

  const handleMouseServiceLeave = () => {
    setIsDropdownServiceOpen(false);
  };

  const handleMouseGalleryEnter = () => {
    if (!isMobileNavOpen) {
      setIsDropdownGalleryOpen(true);
      setColor(true)
    }
  };

  const handleMouseGalleryLeave = () => {
    setIsDropdownGalleryOpen(false);
    setColor(false)
  };


  function handleBurgerClick() {
    if(isMobileNavOpen) {
      setIsMobileNavOpen(false)
    } else {
      setIsMobileNavOpen(true)
    }
  }

  return (
      <nav className="navbar navbar-bg navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img className='nav-logo-mobile' src={navLogo} alt="Logo" />
          </Link>
          <button className="navbar-toggler" type="button" onClick={handleBurgerClick}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`${isMobileNavOpen ? '' : 'collapse'} navbar-collapse`} id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  BERANDA
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/tentang-kami">TENTANG KAMI</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link dropdown-toggle" onClick={handleServiceClickMobile} onMouseEnter={handleMouseServiceEnter} onMouseLeave={handleMouseServiceLeave}>
                  LAYANAN
                </Link>
                {(isDropdownServiceOpen || isServiceMobileOpen) && (
                  <ul className="navbar-dropdown" onMouseEnter={handleMouseServiceEnter} onMouseLeave={handleMouseServiceLeave}>
                    <li><Link className="dropdown-item" to="/tata-rias">Tata Rias</Link></li>
                    <li><Link className="dropdown-item" to="/tata-busana">Tata Busana</Link></li>
                    <li><Link className="dropdown-item" to="/fotografi">Fotografi</Link></li>
                  </ul>
                )}
              </li>
              <Link className="navbar-brand" to="/">
                <img className='nav-logo' src={navLogo} alt="Logo" />
              </Link>
              <li className="nav-item">
                <Link className="nav-link dropdown-toggle" onClick={handleGalleryClickMobile} onMouseEnter={handleMouseGalleryEnter} onMouseLeave={handleMouseGalleryLeave}>GALERI</Link>
                {(isDropdownGalleryOpen || isGalleryMobileOpen) && (
                  <ul className="navbar-dropdown" onMouseEnter={handleMouseGalleryEnter} onMouseLeave={handleMouseGalleryLeave}>
                    <li><Link className="dropdown-item" to="/galeri/makeup">Galeri Tata Rias</Link></li>
                    <li><Link className="dropdown-item" to="/galeri/photography">Galeri Fotografi</Link></li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pricelist">PRICELIST</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog">BLOG</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/kontak">KONTAK</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
}

export default NavigationBarLight;