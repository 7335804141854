import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ibuLiza from '../../assets/images/home/mot-ibu-liza.png'
import fitriLiza from '../../assets/images/home/mot-fitri-liza.png'
import renyLiza from '../../assets/images/home/mot-reny-liza.png'

import tataRias from '../../assets/images/home/tata-rias.png'
import weddingOrganizer from '../../assets/images/home/wedding-organizer.png'
import photography from '../../assets/images/home/photography.png'
import upacaraAdat from '../../assets/images/home/upacara-adat.png'
import CardComponent from '../CardComponent';

function MeetOurTeam() {
  return (
    <div className='home-meet-our-team'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='mot-title-container col-12 col-md-12 col-lg-5' data-aos="fade-up">
          </div>
          <div className='mot-team-container col-12 col-md-12 col-lg-7'>
            <div className='row'>
              <div 
                className='mot-profile-container d-flex col-12 col-md-4 col-lg-4 justify-content-center' 
                data-aos="fade-up"
                data-aos-delay="100">
                <div className='mot-team-profile'> 
                  <img className='mot-img img-responsive img' src={ibuLiza} />
                  <div className='mot-team-desc'>
                    <p className='mot-name'>Ibu Liza & Team</p>
                    <p className='mot-mua'>Make Up Artist</p>
                  </div>
                </div>
              </div>
              <div 
              className='mot-profile-container d-flex col-12 col-md-4 col-lg-4 justify-content-center' 
              data-aos="fade-up"
              data-aos-delay="200">
                <div className='mot-team-profile'> 
                  <img className='mot-img img-responsive img' src={fitriLiza} />
                  <div className='mot-team-desc'>
                    <p className='mot-name'>Fitri Liza & Team</p>
                    <p className='mot-mua'>Make Up Artist</p>
                  </div>
                </div>
              </div>
              <div 
              className='mot-profile-container d-flex col-12 col-md-4 col-lg-4 justify-content-center' 
              data-aos="fade-up"
              data-aos-delay="300">
                <div className='mot-team-profile'> 
                  <img className='mot-img img-responsive img' src={renyLiza} />
                  <div className='mot-team-desc'>
                    <p className='mot-name'>Reny Liza & Team</p>
                    <p className='mot-mua'>Make Up Artist</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetOurTeam;