import { useEffect } from 'react';
import NavigationBarLight from '../../NavigationBarLight';
import Footer from '../../Footer';


function BlogEntry7() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='blog-entry be-7'>
      <NavigationBarLight />
      <div className='container'>
        <div className='be-content'>
          <img className='be-6-img be-img img-responsive' src="https://fokal.id/wp-content/uploads/2024/08/House-Of-Liza-Fashion-Show-4-scaled-e1724035262133-1140x570.jpg"/>
          <h2 className='be-6-title be-title'>“Indonesian Heritage Diversity”, Tema Fashion Show House Of Liza</h2>
          <p className='be-6-desc be-desc'>by Fokal, Rico Muhammad Aziz | August 19, 2024</p>
          <p className='be-text'>Jakarta, fokal.id - Dalam rangka memperingati Hari Kemerdekaan Indonesia, House Of Liza, salah satu nama besar dalam industri Wedding Indonesia, menyelenggarakan sebuah pagelaran busana yang bertajuk “Indonesian Heritage Diversity”.
          <br/>
          <br/>
          Acara ini merupakan Kolaborasi Bersama antara Venue The Tribrata dan House of Liza yang berlangsung pada 16 Agustus 2024 di Opus Grand Ballroom, The Tribrata Hotel & Conventions, Darmawangsa - Jakarta.
          <br/>
          <br/>
          “Indonesian Heritage Diversity” adalah sebuah perayaan keindahan dan kekayaan warisan budaya Indonesia yang diwujudkan melalui karya-karya busana yang memadukan elemen tradisional dan modern. Koleksi yang akan diperkenalkan dalam acara ini merupakan buah karya desainer House Of Liza yang terinspirasi oleh keragaman budaya Nusantara, yang diolah menjadi busana kontemporer yang tetap memancarkan nuansa keaslian tradisi.
          <br/>
          <br/>
          Warna-warna yang digunakan dalam koleksi ini diambil dari palet yang sedang menjadi tren di industri fashion global, namun dengan sentuhan unik berupa detail ukiran dan motif tradisional yang mencerminkan identitas budaya Indonesia. Motif batik, tenun ikat, songket, dan berbagai jenis kain tradisional lainnya akan dipadu dengan potongan modern, menciptakan tampilan yang elegan dan penuh makna.
          <br/>
          <br/>
          <strong>Riasan Tradisional dengan Sentuhan Modern</strong>
          <br/>
          <br/>
          Selain busana, aspek riasan dan tata rambut juga menjadi fokus utama dalam acara ini. House Of Liza akan menampilkan teknik-teknik riasan tradisional dari berbagai daerah di Indonesia, namun dengan sentuhan modern yang membuatnya lebih relevan dengan tren saat ini.
          <br/>
          <br/>
          <strong>Informasi Kontak</strong>
          <br/>
          <br/>
          Untuk informasi lebih lanjut mengenai acara ini, reservasi, atau kerjasama media, silakan hubungi:
          <br/>
          Fitri Liza
          <br/>
          Email: fitri.nursanty@gmail.com
          <br/>
          Telepon: 08119660988
          <br/>
          Website: www.HouseofLiza.co.id</p>
          <br/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogEntry7;
