import { useEffect, useState } from 'react';

import variables from '../variables'

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import Loader from '../components/Loader';

function GaleriFotografi() {
  const [img, setImg] = useState([])
  const [loading, setLoading] = useState(true)

  // function formatAndCapitalize(input) {
  //   // Replace hyphens with spaces and capitalize each word
  //   return input
  //     .replace(/-/g, ' ')
  //     .replace(/\b\w/g, (char) => char.toUpperCase());
  // }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await fetch(`${variables.api}/v1/gallery/photography`); // Replace with your API endpoint
        const res = await response.json();
        setImg(res.data.photography);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  

  return (
    <div className='galeri galeri-fotografi'>
      <NavigationBar />
      <div className='gfg-banner'>
        <div className='container'>
          <div className='gfg-header col-12 col-md-6 col-lg-6' data-aos="fade-up"></div>
        </div>
      </div>
      <div className='galeri-body gfg-body'>
        <div className='container'>
          <div className='row g-2'>
            {loading ? <Loader /> :
            img.map((type) => (
              type.photography_detail && type.photography_detail.map((photo) => (
                <div key={photo.id} className='col-6 col-md-4 col-lg-3 galeri-img-container' data-aos="fade-up">
                  {photo.img_name ? (
                    <img className='img img-fluid svg-img' src={photo.img_name} alt={photo.title} />
                  ) : (
                    <Loader /> 
                  )}
                </div>
              ))
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GaleriFotografi;
