import { Link } from 'react-router-dom';
import ItemCardComponent from '../ItemCardComponent';
import { useRef, useState, useEffect } from 'react';

import Loader from '../Loader';
import variables from '../../variables';

function SongketFeatured() {
  const [featuredData, setFeaturedData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${variables.api}/v1/products/songket?page=1&limit=6`); // Replace with your API endpoint
        const res = await response.json();
        setFeaturedData(res.data.products.category_products);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    
    setFeaturedData([])
    fetchData();
  }, [])

  return (
    <div className="tb-featured tbf-songket">
      <div className='container'>
        <div className='tbf-header'>
          <h2 className='tbf-name' data-aos="fade-right" data-aos-delay="100">Songket</h2>
          <Link className='tbf-link' to="/tata-busana/songket">Lihat Semua</Link>
        </div>
        <div className={`tbf-items tbf-songket-items row justify-content-center`}>
          { loading ? <Loader /> :
            featuredData.map((item) => {
              return (
                <ItemCardComponent key={item.product_code} itemCode={item.product_code} itemName={item.product_name} itemLink={`/tata-busana/songket/detail/${item.product_id}`} itemImg={item.product_image_files} />
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default SongketFeatured;
