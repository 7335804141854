import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ItemCardComponent({ itemCode, itemName, itemImg, itemLink }) {
  const [currImg, setCurrImg] = useState(itemImg[0])

  const handleMouseEnter = () => {
    if (itemImg.length > 1) {
      setCurrImg(itemImg[1])
    }
  }

  const handleMouseLeave = () => {
    setCurrImg(itemImg[0])
  }

  return (
    <div className={`item-card item-${itemCode.toLowerCase()} col-5 col-md-4 col-lg-4`}>
      <img className='item-card-img img-fluid' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} src={currImg} alt={itemCode} />
      <p className='item-card-code'>{itemName}</p>
      <Link to={itemLink} className="item-card-link">
          <div className="item-card-detail">
            <p className="item-card-detail-text">Lihat Detail</p>
          </div>
        </Link>
    </div>
  );
}

export default ItemCardComponent;
