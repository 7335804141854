import React, { useState, useEffect } from 'react';

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer'

import MainBanner from '../components/home/MainBanner';
import MeetOurTeam from '../components/home/MeetOurTeam';
import KenapaMemilih from '../components/home/KenapaMemilih';
import Elegancy from '../components/home/Elegancy'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div>
       <NavigationBar />
       <MainBanner />
       <MeetOurTeam />
       <KenapaMemilih />
       <Elegancy />
       <Footer />
    </div>
  );
}

export default Home;
