import react, { useState, useEffect, useRef } from 'react';

function KontakForm() {
  return (
    <div className='kontak-form order-1 order-md-1 order-lg-2 col-12 col-md-12 col-lg-6'>
      <div className='kf-text'>
        <h2 className='kf-header'>Konsultasikan Hari Spesialmu</h2>
        <p className='kf-desc'>Gunakan form di bawah ini dan costumer Service kami akan segera membantumu</p>
        <div className='kf-body'>
          <div className='row mb-4 justify-content-between'>
            <div className='col-12 col-md-12 col-lg-6'>
                <input className='kf-elmt kf-input' type="text" placeholder='Nama Lengkap' required/>
            </div>
            <div className='col-12 col-md-12 col-lg-6'>
              <input className='kf-elmt kf-input' type="text" placeholder='Email' required/>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-12 col-md-12 col-lg-6'>
              <input className='kf-elmt kf-input' type="text" placeholder='No. Handphone' required/>
            </div>
            <div className='col-12 col-md-12 col-lg-6'>
              <input className='kf-elmt kf-input' type="text" placeholder='Subjek' required/>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-12'>
              <textarea className='kf-elmt kf-msg' placeholder='Pesan Anda'></textarea>
            </div>
          </div>

          <input className='kf-submit-btn' type="submit" value="Kirim" />
        </div>
      </div>
    </div>
  );
}

export default KontakForm;
