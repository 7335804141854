import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import variables from '../variables'

import NavigationBarLight from '../components/NavigationBarLight'
import Footer from '../components/Footer'

import waIcon from '../assets/icons/wa.svg'


function ItemDetailTemplate() {
  let { type } = useParams();
  let { code } = useParams();

  const [currentMenu, setCurrentMenu] = useState(1)
  const [detail, setDetail] = useState([]) 
  const [loading, setLoading] = useState(false)
  const [currentContent, setCurrentContent] = useState('')

  const [currentImg, setCurrentImg] = useState(0)
  const [images, setImages] = useState([])
  const [imgPath, setImgPath] = useState('')

  const productSizeToString = (productSize) => {
    return Object.entries(productSize)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  };

  function renderDetailBody() {
    if (currentMenu === 1) {
      setCurrentContent(detail.product_marketing_word)
    } else if (currentMenu === 2) {
      if (detail.product_size.lingkar_dada === 0) {
        setCurrentContent('Mohon maaf, belum tersedia detail ukuran.')
      } else  {
        setCurrentContent(productSizeToString(detail.product_size.lingkar_dada))
      }
    } else if (currentMenu === 3) {
      setCurrentContent(`Pengiriman:
      Customer Service Online Kami akan menginformasikan pada anda perihal ini

      Syarat dan Ketentuan:
      Pastikan anda telah mendapat informasi yang lengkap tentang produk yang ingin disewa, anda dapat menghubungi Customer Service Online Kami dengan menekan tautan Whatsapp, Berikut adalah point-point yang harus kamu perhatikan sebelum melakukan transaksi lebih lanjut:
      1. Katalog by Boutique Sanggar Liza adalah Koleksi dari Busana-busana Sanggar Liza
      2. Cari busana yang ingin Anda sewa, di katalog ini.
      3. Catat Kode Busana sebelum menghubungi Customer Service Online untuk menanyakan detail   kondisi product
      4. Wajib Isi form appointment online kami sebelum berkunjung.
      5. Menyewa tanda Anda setuju dengan syarat dan ketentuan yang berlaku
      `)
    }
  }

  function renderHighlightImg() {
    if (currentImg === 0) {
      setImgPath(images[0])
    } else if (currentImg === 1) {
      setImgPath(images[1]) 
    }
  }

  function handleMenuClick(id) {
    if (currentMenu === id) {
      return
    }
    setCurrentMenu(id)
  }

  function handleImgClick(id) {
    if (currentImg === id) {
      return
    }

    setCurrentImg(id)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${variables.api}/v1/products/detail/${code}`); // Replace with your API endpoint
        const res = await response.json();
        setDetail(res.data.category_products[0]);
        setImages(res.data.category_products[0].product_image_files);
        setImgPath(images[0])
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    renderDetailBody()
  }, [currentMenu, detail])

  useEffect(() => {
    renderHighlightImg()
  }, [currentImg, detail])
  

  return (
    <div className={`item-detail item-${code}-detail`}>
      <NavigationBarLight />
      {loading ? <p>Loading</p> :
      <div className='id-inner container'>
        <div className='row'>
          <div className='id-img-container col-12 col-md-12 col-lg-6'>
            <div className='row justify-content-center'>
              <div className='col-3 p-0'>
                {images.map((item, i) => {
                    return (
                      <img key={i} className='id-img-preview img img-fluid' src={item} onClick={() => {handleImgClick(i)}}/>
                    )
                  })
                }
              </div>
              <div className='col-9'>
                <img className='id-img-highlight img img-fluid' src={imgPath} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-12 col-lg-6'>
            <div className='id-header'>
              <h2 className='id-title'>{`${type} Eksklusif`}</h2>
              <h3 className='id-code'>{detail.product_name}</h3>
            </div>

            <div className='id-menu'>
              <hr className='id-hr' />
              <div className='id-menu-item'>
                <p className={`id-menu-btn id-desc-btn ${currentMenu === 1 ? 'active': ''}`} onClick={() => {handleMenuClick(1)}}>Deskripsi Busana</p>
                <p className={`id-menu-btn id-size-btn ${currentMenu === 2 ? 'active': ''}`} onClick={() => {handleMenuClick(2)}}>Rincian Ukuran</p>
                <p className={`id-menu-btn id-tos-btn ${currentMenu === 3 ? 'active': ''}`} onClick={() => {handleMenuClick(3)}}>Ketentuan dan Pengiriman</p>
              </div>
              <hr className='id-hr' />
            </div>

            <div className='id-body'>
              <p>{currentContent}</p>
            </div>

            <div className='id-footer'>
              <div className='id-wa'>
                <Link to='/' className='id-wa-inner d-flex'>
                  <div className='id-wa-icon' style={{ maskImage: `url(${waIcon})`, WebkitMaskImage: `url(${waIcon})` }}></div>
                  <p className='id-wa-text'>Hubungi customer service</p>
                </Link>
              </div>
              <hr />
              <p className='id-category'>Kategori: {type}</p>
            </div>
          </div>
        </div>
      </div>
      }
      <Footer />
    </div>
  );
}

export default ItemDetailTemplate;
