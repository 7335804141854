import React from 'react';

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import About from '../components/tentang-kami/About';
import VisiMisi from '../components/tentang-kami/VisiMisi';
import Management from '../components/tentang-kami/Management';


function TentangKami() {
  return (
    <div>
       <NavigationBar />
       <About />
       <VisiMisi />
       <Management />
       <Footer />
    </div>
  );
}

export default TentangKami;
