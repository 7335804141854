import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Fotografi from '../../pages/Fotografi';


import fotografiBannerImg from '../../assets/images/fotografi/fotografi-banner.png';
import fotografiLogo from '../../assets/images/logo/photography-logo.png';


function FotografiBanner() {
  return (
    <div className='fb-container'>
      <div className='container'>
        <div className='fb-inner row'>
          <div className='col-12 col-md-12 col-lg-6'>
            <div className='fb-title' data-aos="fade-right"></div>
            <p className='fb-desc' data-aos="fade-right" data-aos-delay="100">Liza Photography siap mengabadikan setiap moment Anda melalui karya foto dan video</p>
            <div data-aos="fade-right" data-aos-delay="200" className='fb-btn-container'>
              <Link to="https://wa.me/628118019985?text=Halo%20Admin,%20aku%20mau%20konsultasi%20tentang%20layanan%Liza%20Photography." target="__blank">
                <button className='fb-btn'>Konsultasi Gratis Sekarang</button>
              </Link>
              <Link to="https://www.instagram.com/liza_photography/" target="__blank">
                <button className='fb-btn'>Kunjungi Instagram</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FotografiBanner;